import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import "../styles/PasswordScreen.css";

const PasswordScreen = () => {
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [error, setError] = useState("");
  const { verifyPassword } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pin === process.env.REACT_APP_ENTER_WEBSITE_PIN) {
      verifyPassword();
    } else {
      setError("Incorrect PIN. Please try again.");
    }
  };

  const handleClickShowPin = () => {
    setShowPin(!showPin);
  };

  return (
    <Container className="password-container" maxWidth={false} disableGutters>
      <div className="password-content">
        <Paper className="title-paper">
          <Typography variant="h2" className="site-title">
            Kelly and Benjamin's Wedding
          </Typography>
        </Paper>
        <Paper className="password-paper">
          <Typography variant="h4" gutterBottom className="password-title">
            Enter PIN
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="PIN"
              type={showPin ? "text" : "password"}
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              fullWidth
              margin="normal"
              required
              className="password-textfield"
              inputProps={{ maxLength: 4, pattern: "[0-9]*" }} // Limit to 4 digits and numeric input
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle pin visibility"
                      onClick={handleClickShowPin}
                      edge="end"
                    >
                      {showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Typography color="error" className="password-error">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              className="password-button"
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default PasswordScreen;
